import {Box, Button, Card, Grid, IconButton, Theme, Typography,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Breadcrumbs, ContainerLayout, Instant, ZonedDateTime} from "@variocube/app-ui";
import {FilterListIcon} from "@variocube/app-ui/src/icons";
import {createElement, Fragment, useEffect, useState} from "react";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {DeliveryFilterRequest, DeliveryKpi, DeliveryState} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {Loading} from "../Loading";
import {useTenantId} from "../TenantContextProvider";
import {dashboardFilter} from "../uis";
import {DeliveryFilterEdit} from "./DeliveryFilterEdit";
import {Metric} from "./Metric";
import {TagsChart} from "./TagsChart";
import {UtilizationChart} from "./UtilizationChart";
import {AverageStorageTimeMetric} from "./AverageStorageTimeMetric";
import {OverdueTable} from "./OverdueTable";
import { CubesProvider } from "../../domain/CubesProvider";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";

export interface DeliveryData {
	createdAt: Date;
	storedAt?: Date;
	pickupAt?: Date;
	state: string;

	tags: string[];
	boxTypes : string[];
}
let toDate = (dateString?: string) => {
	let result: Date | undefined;
	if (dateString) {
		let instant = Instant.from(dateString);
		result = new Date(instant.epochMilliseconds);
	}
	return result;
};

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
const zdtOneWeekAgo = ZonedDateTime.from({year: oneWeekAgo.getFullYear(), month: oneWeekAgo.getMonth() + 1, day: oneWeekAgo.getDate(), timeZone: "UTC"});	
const defaultTimeframe  = {from: zdtOneWeekAgo.toString()};
export function Dashboard() {
	let emptyFilter: DeliveryFilterRequest = {
		cubeIds: [],
		deliveryConditions: [],
		deliveryStates: [],
		deliveryTypes: [],
		needle: "",
		siteIds: [],
		tags: [],
		creationTimeframe: defaultTimeframe,
		pickupTimeframe: defaultTimeframe,
		storageTimeframe: defaultTimeframe,
	};

	const tenantId = useTenantId();
	const {t} = useLocalization();
	const [loading, setLoading] = useState<boolean>(false);
	const [deli, setDeli] = useState<DeliveryKpi[]>([]);
	const [deliveryData, setDeliveryData] = useState<DeliveryData[]>([]);
	const [filter, setFilter] = useState<DeliveryFilterRequest>(emptyFilter);
	const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
	const [calculated, setCalculated] = useState<boolean>(false);
	const [boxTypes,setBoxTypes] = useState<Set<string>>();
	const [states,setStates] = useState<Map<string, number>>( new Map());

	let guessBoxTypes = async () => {
		let _boxTypes: Set<string> | undefined   = new Set<string>();
		let cubes = await CubesProvider.list(tenantId);
		for (let cube of cubes) {
			let boxes = await CubesProvider.getBoxes(tenantId, cube.hostname);
			for (let box of boxes)
				for (let boxType of box.types)
			      _boxTypes.add(boxType)
		}
		setBoxTypes(_boxTypes)
	}


	let getStates = (deliveryData: DeliveryData[]) => {
		let states: Map<string,number> = new Map<string, number> ;
		for (let d of deliveryData) {
			let state = d.state;
			if (state == DeliveryState.ManualHandover && d.pickupAt)
				state = DeliveryState.PickedUp
			let old: number = states.get(state) ?? 0;
			states.set(state, old+1)
		}
		return states;
	}


	let calculate = async () => {
		setLoading(true);
		let deliveryKpis = await DeliveriesProvider.listKpi(tenantId, filter);
		setDeli(deliveryKpis);

		let deliDates: DeliveryData[] = [];
		for (let d of deliveryKpis) {
			const item: DeliveryData = {
				createdAt: toDate(d.createdAt) ?? new Date(),
				storedAt: toDate(d?.storedAt),
				pickupAt: toDate(d?.pickedUpAt),
				state: d.state,
				tags: [],
				boxTypes: []
			};

			for (let tag of d?.tags || []) {
				if (boxTypes?.has(tag))
					item.boxTypes.push(tag)
				else
					item.tags.push(tag);
			}
			if (item.boxTypes.length == 0)
				item.boxTypes.push("?")
			deliDates.push(item);
		}
		setDeliveryData(deliDates);
		setStates( getStates(deliDates));
		setLoading(false);
		setCalculated(true)
	};

	useEffect(() => {
		if (dashboardFilter && dashboardFilter.get()) {
			const usedFilter = dashboardFilter.get();
			if (usedFilter && usedFilter.creationTimeframe && usedFilter.creationTimeframe.from == undefined) {
				usedFilter.creationTimeframe = defaultTimeframe;
			}
			if (usedFilter && usedFilter.pickupTimeframe && usedFilter.pickupTimeframe.from == undefined) {
				usedFilter.pickupTimeframe = defaultTimeframe;
			}
			if (usedFilter && usedFilter.storageTimeframe && usedFilter.storageTimeframe.from == undefined) {
				usedFilter.storageTimeframe = defaultTimeframe;
			}
			dashboardFilter.set(usedFilter as DeliveryFilterRequest);
		}
		setFilter(dashboardFilter.get() ?? emptyFilter);
		guessBoxTypes();
	}, []);


	function filterChanged(deliveryFilterRequest: DeliveryFilterRequest) {
		setFilter(deliveryFilterRequest);
		setCalculated(false);
	}
	function filterDialogClosed() {
		setFilterDialogOpen(false);
		calculate();
	}

	return (
		<ContainerLayout>
			{deliveryData
				&& (
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Breadcrumbs>
								<BreadcrumbRouterLink to={`/${tenantId}/dashboard`}>{t("dashboard.singular")}</BreadcrumbRouterLink>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h2">{t("dashboard.singular")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<Box p={2}>
									<Grid container>
										<Grid item alignItems="center">
											<IconButton onClick={() => setFilterDialogOpen(true)}>
												<FilterListIcon />
											</IconButton>
											<Box mx={1} />
										</Grid>
										<Grid item style={{flexGrow: 1, alignContent:'center'}} alignItems="center">
											<DeliveryFilterEdit
												dialogOpen={filterDialogOpen}
												onFilterChanged={filterChanged}
												onDialogClosed={filterDialogClosed}
												appStorage={dashboardFilter}
											/>
										</Grid>
										<Grid item>
											<Button onClick={() => calculate()}>{t("dashboard.calculate")}</Button>
										</Grid>
									</Grid>
								</Box>
							</Card>
						</Grid>

						{loading
							&& (
								<Grid item>
									<Loading />
								</Grid>
							)}
						{!loading && calculated
							&& (
								<Fragment>
									<Grid item xs={3}>
										<Metric label={t("deliveries.plural")} value={deliveryData.length} />
									</Grid>
									<Grid item xs={3}>
										<Metric label={t("deliveries.deliveryStates.PickedUp")} value={states?.get("PickedUp") ?? 0} />
									</Grid>
									<Grid item xs={3}>
										<Metric label={t("deliveries.deliveryStates.Cancelled")} value={states?.get("Cancelled") ?? 0} />
									</Grid>
									<Grid item xs={3}>
										<Metric label={t("deliveries.deliveryStates.Stored")} value={states?.get("Stored") ?? 0} />
									</Grid>
									<Grid item xs={6}>
										<AverageStorageTimeMetric deliveries={deliveryData} />
									</Grid>
									{ deli.length > 0 && (
										<Fragment>
											<Grid item xs={12}>
												<UtilizationChart dates={deliveryData} />
											</Grid>
											<Grid item xs={12}>
												<Box sx={{height: 100}} />
											</Grid>
											<Grid item xs={12}>
												<TagsChart deliveries={deliveryData} chartType={"ColumnChart"} />
											</Grid>
											<Grid item xs={12}>
												<Box sx={{height: 100}} />
											</Grid>
											<Grid item xs={12}>
												<TagsChart deliveries={deliveryData} chartType={"PieChart"} />
											</Grid>
											<Grid item xs={12}>
												<Box sx={{height: 100}} />
											</Grid>
											<Grid item xs={12}>
												<OverdueTable deliveries={deli} chartType={"PieChart"} />
											</Grid>
										</Fragment>
									)}
								</Fragment>
							)}
					</Grid>
				)}
		</ContainerLayout>
	);
}
makeStyles((theme: Theme) => ({
	image: {
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
	},
	imageAnalyzing: {
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
		filter: "grayscale(100%)",
	},
}));
